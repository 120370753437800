import { render, staticRenderFns } from "./batch-edit-cost.vue?vue&type=template&id=b88e3a56&scoped=true"
import script from "./batch-edit-cost.ts?vue&type=script&lang=ts&external"
export * from "./batch-edit-cost.ts?vue&type=script&lang=ts&external"
import style0 from "./batch-edit-cost.scoped.scss?vue&type=style&index=0&id=b88e3a56&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b88e3a56",
  null
  
)

export default component.exports